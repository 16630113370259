type ProductNameProps = {
  productName?: string;
  requestedProductName?: string;
  href?: string;
};

const ProductName = ({
  productName,
  requestedProductName,
}: ProductNameProps): JSX.Element | null => {
  if (!productName) {
    return null;
  }

  return (
    <div className='flex flex-column justify-content-center'>
      <div className='font-normal max-w-fit'>{productName}</div>
      {requestedProductName && (
        <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600 line-height-2'>
          {requestedProductName}
        </div>
      )}
    </div>
  );
};

export default ProductName;
