import { IDropdownItem } from '../interfaces/IDropdownItem';

export const prepareInitiallySelectedItems = (
  options: IDropdownItem[],
  initialValues?: string[],
): IDropdownItem[] => {
  return options.filter((option) => {
    return initialValues?.includes(option.code);
  });
};
