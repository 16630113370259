import { Auth } from 'aws-amplify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import useWebsocket, { ReadyState } from 'react-use-websocket';

import { useAuth } from '../../hooks/auth/useAuth';
import WebsocketContext, {
  IWebsocketContext,
  IWebsocketMessage,
  WebsocketEventType,
} from './WebsocketContext';

/**
 * Retrieves the WebSocket URL with the appended idToken, to be used by useWebsocket method.
 * @returns A promise that resolves to the WebSocket URL with the idToken.
 */
export const getWebsocketUrl = async (): Promise<string> => {
  const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();

  return process.env.REACT_APP_WEBSOCKET_URL + '?idToken=' + idToken;
};

type WebsocketProviderProps = {
  children: React.ReactNode;
};

const WebsocketProvider = ({ children }: WebsocketProviderProps): JSX.Element => {
  const { isAuthenticated } = useAuth();

  const { lastMessage, lastJsonMessage, readyState } = useWebsocket(
    getWebsocketUrl,
    {
      shouldReconnect: () => {
        return true;
      },
      share: true,
    },
    isAuthenticated,
  );

  // Consumers' callback functions to be called when a new message arrives
  // These functions are mapped to the event types. Each event type acts as if a channel
  const [callbacks, setCallbacks] = useState(
    {} as Record<WebsocketEventType, ((message: IWebsocketMessage) => void)[]>,
  );
  const [lastProcessedMessage, setLastProcessedMessage] = useState<string>('');

  // Function to register a callback function of a child component to be called when a new message arrives
  // ** Private method **
  const registerCallback = useCallback(
    (eventType: WebsocketEventType, callback: (message: IWebsocketMessage) => void) => {
      setCallbacks((prev) => {
        const currentCallbacks = prev[eventType] || [];
        return {
          ...prev,
          [eventType]: [...currentCallbacks, callback],
        };
      });
    },
    [],
  );

  // Function to unregister a callback function of a child component when it is unmounted
  // ** Private method **
  const unregisterCallback = useCallback(
    (eventType: WebsocketEventType, callback: (message: IWebsocketMessage) => void) => {
      setCallbacks((prev) => {
        const filteredCallbacks = prev[eventType]?.filter((cb) => {
          return cb.toString() !== callback.toString();
        });

        return {
          ...prev,
          [eventType]: filteredCallbacks,
        };
      });
    },
    [],
  );

  // Custom hook to register a callback function which will come from the children component
  // They will be able to react to the websocket messages without explicitly listening to the websocket
  // And those callbacks will be executed when a new message arrives, here in the provider level, not in the component level
  const useRegisterCallback = (
    eventType: WebsocketEventType,
    callback: (message: IWebsocketMessage) => void,
  ) => {
    return useEffect(() => {
      if (callback && registerCallback && unregisterCallback) {
        registerCallback(eventType, callback);

        // When component unmounts, it will unregister its callback function
        return () => {
          return unregisterCallback(eventType, callback);
        };
      }
    }, [registerCallback, unregisterCallback]);
  };

  // Listen to the websocket messages
  useEffect(() => {
    if (
      readyState === ReadyState.OPEN &&
      lastJsonMessage &&
      JSON.stringify(lastJsonMessage) !== lastProcessedMessage
    ) {
      // Update the last processed message ID
      setLastProcessedMessage(JSON.stringify(lastJsonMessage));

      const message = lastJsonMessage as IWebsocketMessage;

      // Execute all registered callbacks
      callbacks?.[message.eventType]?.forEach((callback) => {
        return callback(message);
      });
    }
  }, [lastJsonMessage, readyState, lastProcessedMessage, callbacks]);

  const state = useMemo<IWebsocketContext>(() => {
    return { lastMessage, lastJsonMessage, readyState, useRegisterCallback };
  }, [lastMessage, lastJsonMessage, readyState, useRegisterCallback]);

  return <WebsocketContext.Provider value={state}>{children}</WebsocketContext.Provider>;
};

export default WebsocketProvider;
