import { DialogProps } from 'primereact/dialog';
import { ComponentType, LazyExoticComponent, Suspense } from 'react';

import useDialogContext from '../../hooks/dialog/useDialogContext';
import { DialogMapper } from './DialogMapper';

const DialogManager = (): JSX.Element | null => {
  const { activeDialog, dialogProps, closeDialog } = useDialogContext();

  if (activeDialog === null || activeDialog === undefined) return null;

  // Get the lazy-loaded component from DialogMapper
  const LazyDialogComponent = DialogMapper[
    activeDialog as keyof typeof DialogMapper
  ] as LazyExoticComponent<ComponentType<DialogProps>>;

  return (
    <Suspense fallback={null}>
      <LazyDialogComponent {...dialogProps} onHide={closeDialog} />
    </Suspense>
  );
};

export default DialogManager;
