import { ComponentProps, createContext } from 'react';

import { DialogMapper } from '../../components/dialog-manager/DialogMapper';
import { IDialogContext } from '../../shared/interfaces/IDialogContext';

export type PropsType<T extends keyof typeof DialogMapper> = Omit<
  ComponentProps<(typeof DialogMapper)[T]>,
  'onHide'
>;

const defaultState: IDialogContext = {
  activeDialog: null,
  dialogProps: {},
  openDialog: () => {
    throw new Error('openDialog() must be implemented');
  },
  closeDialog: () => {
    throw new Error('closeDialog() must be implemented');
  },
};

const DialogContext = createContext(defaultState);

export default DialogContext;
