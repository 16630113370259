import { Button, ButtonProps } from 'primereact/button';
import { classNames, IconOptions } from 'primereact/utils';
import { useMatch, useNavigate } from 'react-router-dom';

import styles from './HeaderLink.module.css';

type HeaderLinkProps = {
  href: string;
  label: string;
  icon?: (options: IconOptions<ButtonProps, unknown>) => JSX.Element;
  activeIcon?: (options: IconOptions<ButtonProps, unknown>) => JSX.Element;
};

const HeaderLink = ({ href, label, icon, activeIcon }: HeaderLinkProps) => {
  const match = useMatch(`${href}/*`);
  const navigate = useNavigate();

  const onClick = () => {
    navigate(href);
  };

  return (
    <Button
      className={classNames('border-noround h-full px-4 text-white', styles['header-link'], {
        [styles['active-border'] as string]: !!match,
      })}
      pt={{
        icon: {
          className: 'text-white',
        },
      }}
      onClick={onClick}
      icon={match ? activeIcon : icon}
      label={label}
      text
    />
  );
};

export default HeaderLink;
