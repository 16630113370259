type ProductIdProps = {
  productId?: string;
  requestedProductId?: string;
};

export default function ProductId({
  productId,
  requestedProductId,
}: ProductIdProps): JSX.Element | null {
  if (!productId) {
    return null;
  }

  return (
    <div className='flex flex-column justify-content-center'>
      <div className='font-normal'>{productId}</div>
      {requestedProductId && (
        <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600'>
          {requestedProductId}
        </div>
      )}
    </div>
  );
}
