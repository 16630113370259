import { useContext } from 'react';

import DialogContext from '../../contexts/dialog/DialogContext';
import { IDialogContext } from '../../shared/interfaces/IDialogContext';

const useDialogContext = (): IDialogContext => {
  const state = useContext(DialogContext);

  if (state === undefined) {
    throw new Error('useDialogContext must be used within a NotificationProvider');
  }

  return { ...state };
};

export default useDialogContext;
