type ProductIdProps = {
  productId?: string;
  ownProductId?: string;
};

export default function ProductId({ productId, ownProductId }: ProductIdProps): JSX.Element | null {
  if (!productId) {
    return null;
  }

  const secondaryProductId = ownProductId ?? null;

  return (
    <div className='flex flex-column justify-content-center'>
      <div className='font-normal'>{productId}</div>
      {secondaryProductId && (
        <div className='white-space-nowrap overflow-hidden text-overflow-ellipsis w-full font-normal font-italic text-gray-600'>
          {secondaryProductId}
        </div>
      )}
    </div>
  );
}
