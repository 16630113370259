import { OverlayPanel } from 'primereact/overlaypanel';
import { MouseEvent, useContext, useEffect, useRef } from 'react';

import WebsocketContext, {
  WebsocketEventType,
} from '../../../../../contexts/websocket/WebsocketContext';
import { useListNotifications } from '../../../../../hooks/api/notifications-api';
import useNotificationContext from '../../../../../hooks/notification/useNotificationContext';
import styles from './Notification.module.css';
import NotificationList, { TOTAL_NOTIFICATIONS_LIST_SIZE } from './NotificationList';

const Notifications = () => {
  const overlayPanelRef = useRef<OverlayPanel | null>(null);

  const { useRegisterCallback } = useContext(WebsocketContext);
  const { isNewNotification, setIsNewNotification } = useNotificationContext(); // To show the green dot on the bell icon

  // RQ Query to get unread notifications
  const { data: unreadNotifData } = useListNotifications({
    size: TOTAL_NOTIFICATIONS_LIST_SIZE,
    skip: 0,
    read: false,
  });

  useEffect(() => {
    if (unreadNotifData?.notifications && unreadNotifData.notifications.length > 0) {
      setIsNewNotification(true);
    }
  }, [unreadNotifData?.notifications]);

  // Callback function for handling websocket messages
  const handleWebsocketMessage = () => {
    // Make green mark visible
    setIsNewNotification(true);
  };

  // Register the callback function for handling websocket messages
  useRegisterCallback(WebsocketEventType.NewNotification, handleWebsocketMessage);

  const onButtonClick = (event: MouseEvent<HTMLAnchorElement>) => {
    overlayPanelRef.current?.toggle(event);
  };

  return (
    <>
      <a className='topbar-action rounded-circle mr-3 text-white' onClick={onButtonClick}>
        <span className='icon-overlay'>
          <i className='pi pi-bell' style={{ fontSize: '1.3rem' }} />
          {isNewNotification && <span className='icon-dot' />}
        </span>
      </a>
      <OverlayPanel
        ref={overlayPanelRef}
        className='shadow-2 mt-2 p-0'
        pt={{
          content: { className: `p-0 ${styles['notification-panel-width']}` },
        }}
      >
        <NotificationList />
      </OverlayPanel>
    </>
  );
};

export default Notifications;
