import { Skeleton } from 'primereact/skeleton';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../../../hooks/auth/useAuth';
import { OtherRequestDataType } from '../../../../../shared/interfaces/IProduct';
import InplaceEdit from '../../inplace-edit/InplaceEdit';

type ProductNameSectionProps = {
  isLoading: boolean;
  mainProductName: string | undefined;
  ownProductName?: string | undefined;
  otherRequestData?: OtherRequestDataType;
};

const ProductNameSection = ({
  isLoading,
  mainProductName,
  ownProductName,
}: ProductNameSectionProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useAuth();

  const secondaryName = ownProductName ?? '';

  const addYourOwnProductNameTemplate = (
    <div className='flex flex-row gap-2 align-items-center'>
      <i className='pi pi-plus-circle text-primary text-2xl' />
      <span className='text-primary font-bold'>
        {t('productDetailsPage.sections.productNameSection.undefinedValue')}
      </span>
    </div>
  );
  if (isLoading) {
    return (
      <div className='px-3'>
        <h1 className='text-3xl'>
          <Skeleton height='29.39px' width='25%' />
        </h1>

        <div>
          <Skeleton height='28.25px' width='20%' />
        </div>
      </div>
    );
  }
  return (
    <div className='flex flex-column px-3'>
      <h1 className='text-3xl pb-0 mb-1'>{mainProductName}</h1>

      {user?.company?.license !== 'basic' && (
        <InplaceEdit
          fieldId='ownProductName'
          value={secondaryName}
          textClassname='text-2xl'
          displayClassname='font-bold'
          customUndefinedDisplay={addYourOwnProductNameTemplate}
        />
      )}
    </div>
  );
};

export default ProductNameSection;
