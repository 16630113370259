export enum NotificationMessage {
  CreateRequestAdditionalProduct = 'create_request_additional_product',
  UpdateRequestAdditionalProduct = 'update_request_additional_product',
  CreateRequestJsonIncreaseLimit = 'create_request_json_increase_limit',
  UpdateRequestJsonIncreaseLimit = 'update_request_json_increase_limit',
  CreateRequestInitialProductList = 'create_request_initial_product_list',
  UpdateRequestInitialProductList = 'update_request_initial_product_list',
  CreateRequestSustainableAlternative = 'create_request_sustainable_alternative',
}

export const NotificationMessageMap: { [key: string]: string } = {
  [NotificationMessage.CreateRequestAdditionalProduct]: 'Add Additional Product:',
  [NotificationMessage.UpdateRequestAdditionalProduct]: 'Add Additional Product:',
  [NotificationMessage.CreateRequestJsonIncreaseLimit]: 'Reset JSON Limit',
  [NotificationMessage.UpdateRequestJsonIncreaseLimit]: 'Reset JSON Limit',
  [NotificationMessage.CreateRequestInitialProductList]: 'List Purchased Products',
  [NotificationMessage.UpdateRequestInitialProductList]: 'List Purchased Products',
};
