import { useTranslation } from 'react-i18next';

import { mapProductRegionToKey } from '../../enums/product-region';
import { IProduct } from '../../interfaces/IProduct';

type ProductRegionProps = {
  product: IProduct;
};

const ProductRegion = ({ product }: ProductRegionProps) => {
  const { t } = useTranslation();

  if (!product.region) return;

  return t(`productsPage.regionNames.${mapProductRegionToKey(product.region)}`);
};

export default ProductRegion;
