import { PrimeReactProvider } from 'primereact/api';
import { BrowserRouter } from 'react-router-dom';

import DialogManager from './components/dialog-manager/DialogManager';
import { DialogProvider } from './contexts/dialog/DialogProvider';
import NotificationProvider from './contexts/notification/NotificationProvider';
import WebsocketProvider from './contexts/websocket/WebsocketProvider';
import { AppRouter } from './routes';

// Fix for iPhone viewport scaling issue
if (navigator.userAgent.indexOf('iPhone') > -1) {
  document
    ?.querySelector('[name=viewport]')
    ?.setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1');
}

function App(): React.ReactElement {
  return (
    <NotificationProvider>
      <PrimeReactProvider value={{ ripple: true }}>
        <WebsocketProvider>
          <BrowserRouter>
            <DialogProvider>
              <DialogManager />

              <AppRouter />
            </DialogProvider>
          </BrowserRouter>
        </WebsocketProvider>
      </PrimeReactProvider>
    </NotificationProvider>
  );
}

export default App;
