import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';

import { useAuth } from '../../../hooks/auth/useAuth';
import useDialogContext from '../../../hooks/dialog/useDialogContext';
import { PCFBiogenicEmissionsMode } from '../../../shared/enums/pcf';
import { IProduct } from '../../../shared/interfaces/IProduct';

type ExportOrRequestPcfsButtonsProps = {
  biogenicEmissionsMode: PCFBiogenicEmissionsMode;
  productsToShowPendingDuringBulk: IProduct[];
  setProductsToShowPendingDuringBulk: (products: IProduct[]) => void;
};

const ExportOrRequestPcfsButtons = ({
  biogenicEmissionsMode,
  productsToShowPendingDuringBulk,
  setProductsToShowPendingDuringBulk,
}: ExportOrRequestPcfsButtonsProps): JSX.Element | null => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { openDialog } = useDialogContext();

  if (user?.company?.license === 'basic') {
    return null;
  }

  const onClickExportPcfs = () => {
    openDialog('export-pcfs-dialog', { biogenicEmissionsMode });
  };

  const onClickRequestPcfs = () => {
    openDialog('bulk-request-pcfs-dialog', {
      productsToShowPendingDuringBulk,
      setProductsToShowPendingDuringBulk,
    });
  };

  return (
    <div className='flex flex-column md:flex-row w-full justify-content-end gap-2 mb-4'>
      <Button
        className='justify-content-center'
        outlined
        onClick={onClickExportPcfs}
        label={t('productsPage.bulkPcfExport')}
      />
      <Button
        className='justify-content-center'
        onClick={onClickRequestPcfs}
        label={t('productsPage.bulkPcfRequest')}
      />
    </div>
  );
};

export default ExportOrRequestPcfsButtons;
