import { Button } from 'primereact/button';
import { Checkbox, CheckboxChangeEvent } from 'primereact/checkbox';
import { OverlayPanel } from 'primereact/overlaypanel';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { prepareInitiallySelectedItems } from '../../shared/helpers/initially-selected-items-helper';
import { IDropdownItem } from '../../shared/interfaces/IDropdownItem';
import styles from './CustomMultiSelect.module.css';

type CustomMultiSelectProps = {
  options: IDropdownItem[];
  label: string;
  maxSelectedOptions: number;
  className?: string;
  onApplyFilter: (selectedItems: IDropdownItem[]) => void;
  onClearFilter: () => void;
  initialValues?: string[];
};

export default function CustomMultiSelect({
  options,
  label,
  maxSelectedOptions,
  className,
  onApplyFilter,
  onClearFilter,
  initialValues,
}: CustomMultiSelectProps) {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [checkedItems, setCheckedItems] = useState<IDropdownItem[]>(
    prepareInitiallySelectedItems(options, initialValues),
  );
  const [selectedItems, setSelectedItems] = useState<IDropdownItem[]>(
    prepareInitiallySelectedItems(options, initialValues),
  );
  const [overlayStatus, setOverlayStatus] = useState<boolean>(false);
  const [isApplyFilter, setIsApplyFilter] = useState<boolean>(!!initialValues?.length);
  const { t } = useTranslation();

  // Listen to initialValues changes, then update checkedItems & selectedItems
  useEffect(() => {
    setCheckedItems(prepareInitiallySelectedItems(options, initialValues));
    setSelectedItems(prepareInitiallySelectedItems(options, initialValues));
  }, [initialValues]);

  const onOptionChange = (e: CheckboxChangeEvent) => {
    let _checkedItems = [...checkedItems];

    if (e.checked) {
      _checkedItems.push(e.value);
    } else {
      _checkedItems = _checkedItems.filter((item) => {
        return item.code !== e.value.code;
      });
    }

    setCheckedItems(_checkedItems);
  };

  const onFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    overlayPanelRef.current?.toggle(event);
  };

  const setFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    overlayPanelRef.current?.toggle(event);
    setIsApplyFilter(true);
    setSelectedItems(checkedItems);
    onApplyFilter(checkedItems);
  };

  const clearFilter = (event: React.MouseEvent<HTMLElement>) => {
    setIsApplyFilter(false);
    setSelectedItems([]);
    setCheckedItems([]);
    event.stopPropagation();
    onClearFilter();
  };

  return (
    <>
      <Button
        type='button'
        className={classNames(
          `flex justify-content-between gap-3 ${overlayStatus ? styles['open'] : ''}`,
          className,
        )}
        onClick={onFilterClick}
        severity='info'
      >
        {isApplyFilter && selectedItems?.length ? (
          <>
            <span className={styles['selected-label']}>{label}:</span>
            <span className='text-900 custom-target flex w-full flex justify-content-center'>
              {selectedItems?.length <= maxSelectedOptions
                ? selectedItems
                    .map((item) => {
                      return item.name;
                    })
                    .join(', ')
                : `${selectedItems?.length} ${t('productsPage.itemsSelected')}`}
            </span>{' '}
            <Tooltip target='.custom-target' />
          </>
        ) : (
          <span>{label}</span>
        )}

        {isApplyFilter && selectedItems?.length ? (
          <i className='pi pi-times ml-2 z-1' onClick={clearFilter} />
        ) : (
          <i className='pi pi-angle-down ml-2' />
        )}
      </Button>

      <OverlayPanel
        ref={overlayPanelRef}
        className={styles['option-panel']}
        onHide={() => {
          setOverlayStatus(false);
        }}
        onShow={() => {
          setOverlayStatus(true);
        }}
      >
        <div className='flex flex-column gap-3'>
          {options.map((option) => {
            return (
              <div key={option.code} className='flex'>
                <Checkbox
                  inputId={option.code}
                  name='code'
                  value={option}
                  onChange={onOptionChange}
                  checked={checkedItems.some((item) => {
                    return item.code === option.code;
                  })}
                />

                <label htmlFor={option.code} className='pl-3 cursor-pointer'>
                  {option.name}
                </label>
              </div>
            );
          })}
        </div>

        <Button
          label={t('productsPage.applyFilter')}
          outlined
          className='mt-3'
          onClick={setFilter}
        />
      </OverlayPanel>
    </>
  );
}
